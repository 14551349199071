/**
 * Defines web constants that do not change
 *
 * @author Duc Minh Ha
 *
 * @since 2020-10-19
 */
export const WebConstants = {
    // Codes
    SUCCESS_CODE: 200,
    BAD_REQUEST_EXCEPTION_CODE: 400,
    UNAUTHORIZED_EXCEPTION_CODE: 401,
    NOT_FOUND_EXCEPTION_CODE: 404,
    GONE_EXCEPTION_CODE: 410,
    INTERNAL_SERVER_ERROR_EXCEPTION_CODE: 500,
    NOT_IMPLEMENTED_EXCEPTION_CODE: 501,
    INVALID_INDEX_EXCEPTION_CODE: 31011,

    CONTENT_TYPE_JSON: {
        'Content-type': 'application/json',
    },

    /**
     * @returns Registry of all exception codes with their messages
     */
    getExceptionMessages() {
        return {
            [WebConstants.BAD_REQUEST_EXCEPTION_CODE]: 'Bad request',
            [WebConstants.UNAUTHORIZED_EXCEPTION_CODE]: 'Unauthorized',
            [WebConstants.NOT_FOUND_EXCEPTION_CODE]: 'Resource not found',
            [WebConstants.INTERNAL_SERVER_ERROR_EXCEPTION_CODE]: 'Internal server error',
            [WebConstants.NOT_IMPLEMENTED_EXCEPTION_CODE]: 'Not yet implemented',
            [WebConstants.INVALID_INDEX_EXCEPTION_CODE]: 'Invalid index provided',
        };
    },
};
