import * as SQS from '@aws-sdk/client-sqs';

// Traits
import { AWSGatewayTrait } from './traits/gateway.aws';

/**
 * @author Duc Minh Ha
 *
 * @since 2020-10-14
 *
 * @param {moduleExports} [target={}]
 *
 * @returns Gateway for interacting with AWS SQS
 */
export const SQSGatewayTrait = (target = {}) => {
    const traits = AWSGatewayTrait(target);
    const parent = { ...traits };

    const moduleExports = {
        ...traits,

        /**
         * Creates a connection to SQS if not already
         *
         * @param {SQS.SQSClientConfig} [config=]
         *
         * @returns {SQS.SQSClient}
         */
        getClient(config) {
            return parent.getClient(SQS.SQSClient, config);
        },

        /**
         * @param {object} options
         * @param {string} options.name
         * @param {object} [options.tags]
         * @param {object} [options.attributes]
         * @param {SQS.SQSClientConfig} [options.clientConfig={}]
         *
         * @returns {Promise<SQS.CreateQueueCommandOutput>} SQS create queue
         */
        async create({
            name,
            tags,
            attributes,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new SQS.CreateQueueCommand({
                        QueueName: name,
                        tags,
                        Attributes: attributes,
                    })),
                action: 'SQS create queue',
                logResponse: true,
            });
        },

        /**
         * @param {object} options
         * @param {string} options.queueURL
         * @param {SQS.SQSClientConfig} [options.clientConfig={}]
         *
         * @returns {Promise<SQS.DeleteQueueCommandOutput>} SQS delete queue
         */
        async delete({
            queueURL,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new SQS.DeleteQueueCommand({
                        QueueUrl: queueURL,
                    })),
                action: 'SQS delete queue',
                logResponse: true,
            });
        },

        /**
         * @param {object} options
         * @param {string} options.queueURL
         * @param {Array<string>} options.attributeNames
         * @param {SQS.SQSClientConfig} [options.clientConfig={}]
         *
         * @returns {Promise<SQS.GetQueueAttributesCommandOutput>} SQS get queue attributes
         */
        async getQueueAttributes({
            queueURL,
            attributeNames,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new SQS.GetQueueAttributesCommand({
                        QueueUrl: queueURL,
                        AttributeNames: attributeNames,
                    })),
                action: 'SQS get queue attributes',
                logResponse: true,
            });
        },

        /**
         * @param {object} options
         * @param {string} options.queueURL
         * @param {SQS.SendMessageBatchRequestEntry[]} options.messages
         * @param {SQS.SQSClientConfig} [options.clientConfig={}]
         *
         * @returns {Promise<SQS.SendMessageBatchCommandOutput>} SQS get queue attributes
         */
        async sendMessages({
            queueURL,
            messages,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new SQS.SendMessageBatchCommand({
                        QueueUrl: queueURL,
                        Entries: messages,
                    })),
                action: 'SQS batch send messages',
                logResponse: true,
            });
        },
    };

    return Object.assign(target, moduleExports);
};

export const SQSGateway = SQSGatewayTrait();
