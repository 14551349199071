import * as EC2 from '@aws-sdk/client-ec2';

// Traits
import { AWSGatewayTrait } from './traits/gateway.aws';

/**
 * @author Duc Minh
 *
 * @since 2021-02-23
 *
 * @param {moduleExports} [target={}]
 *
 * @returns Gateway for interacting with EC2 servers
 */
export const EC2GatewayTrait = (target = {}) => {
    const traits = AWSGatewayTrait(target);
    const parent = { ...traits };

    const moduleExports = {
        ...traits,

        /**
         * Creates a connection to EC2 if not already
         *
         * @param {EC2.EC2ClientConfig} [config]
         *
         * @returns {EC2.EC2Client}
         */
        getClient(config) {
            return parent.getClient(EC2.EC2Client, config);
        },

        /**
         * Put an object into EC2 in using the specified bucket and key
         *
         * @param {object} options
         * @param {boolean} [options.allRegions=true]
         * @param {EC2.EC2ClientConfig} [options.clientConfig]
         *
         * @returns {Promise<EC2.DescribeRegionsCommandOutput>}
         */
        async describeRegions({
            allRegions = true,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new EC2.DescribeRegionsCommand({
                        AllRegions: allRegions,
                    })),
                action: 'EC2 put object',
            });
        },

        /**
         * Describe instances by Id
         *
         * @param {object} options
         * @param {string[]} options.instanceIds
         * @param {EC2.EC2ClientConfig} [options.clientConfig]
         *
         * @returns {Promise<EC2.DescribeRegionsCommandOutput>}
         */
        async describeInstances({
            instanceIds,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new EC2.DescribeInstancesCommand({
                        InstanceIds: instanceIds,
                    })),
                action: 'EC2 describe instances by Id',
            });
        },

        /**
         * Terminate instances by id
         *
         * @param {object} options
         * @param {string[]} options.instanceIds
         * @param {EC2.EC2ClientConfig} [options.clientConfig]
         *
         * @returns {Promise<EC2.TerminateInstancesCommandOutput>}
         */
        async terminateInstances({
            instanceIds,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new EC2.TerminateInstancesCommand({
                        InstanceIds: instanceIds,
                    })),
                action: 'EC2 terminate instances by id',
                logResponse: true,
            });
        },
    };

    return Object.assign(target, moduleExports);
};

export const EC2Gateway = EC2GatewayTrait();
