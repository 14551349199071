import dotenv from 'dotenv';
import * as fs from 'fs';
import { Ultilities } from '../ultilities/ultilities.common';

dotenv.config({ debug: process.env.DEBUG });

/**
 * Retrieves configs for the application
 *
 * @author Duc Minh Ha
 *
 * @since 2020-10-19
 *
 * @param {moduleExports} [target={}]
 *
 * @returns Config class
 */
export const ConfigTrait = (target = {}) => {
    const moduleExports = {
        /**
         * Configs retrieved from environment
         * Cached to improve performance
         *
         * @type {object}
         */
        $config: null,

        /**
         * @returns {NodeJS.ProcessEnv} Config from the environment
         */
        getConfig() {
            if (!target.$config) {
                // Assign local variables if set
                let envConfig = {};
                if (process.env.LOCAL) {
                    if (process.env.LOCAL_TYPE === 'json') {
                        envConfig = Ultilities.fromJSON(fs.readFileSync(process.env.LOCAL, 'utf-8'));
                    } else {
                        dotenv.config({ path: process.env.LOCAL });
                    }
                }
                // Assign environment variables to cache
                Object.assign(target, {
                    $config: {
                        ...Object.keys(process.env).reduce((prev, curr) => ({
                            ...prev,
                            [curr]: Ultilities.fromJSON(process.env[curr]) || process.env[curr],
                        }), {}),
                        ...envConfig,
                    },
                });
            }

            return target.$config;
        },

        /**
         * @returns {object} Config from the environment manifest
         */
        getAppConfig() {
            return process.env.APP_MANIFEST;
        },

        /**
         * @returns {object} Config from the environment extra settings
         */
        getAppExtraConfig() {
            return target.getAppConfig().extra;
        },

        /**
         * @param {string} name Name of the config
         *
         * @returns {*} The config object by the name given
         */
        getConfigByName(name) {
            const config = target.getConfig();
            return config[name];
        },
    };

    return Object.assign(target, moduleExports);
};

export const Config = ConfigTrait();
