import * as SNS from '@aws-sdk/client-sns';

// Traits
import { AWSGatewayTrait } from './traits/gateway.aws';

/**
 * @author Duc Minh Ha
 *
 * @since 2020-10-14
 *
 * @param {moduleExports} [target={}]
 *
 * @returns Gateway for interacting with AWS SNS
 */
export const SNSGatewayTrait = (target = {}) => {
    const traits = AWSGatewayTrait(target);
    const parent = { ...traits };

    const moduleExports = {
        ...traits,

        /**
         * Creates a connection to SNS if not already
         *
         * @param {SNS.SNSClientConfig} [config]
         *
         * @returns {SNS.SNSClient}
         */
        getClient(config) {
            return parent.getClient(SNS.SNSClient, config);
        },

        /**
         * Publishes a message to the target topic ARN
         *
         * @param {object} options
         * @param {string} options.topicArn
         * @param {string} options.message
         * @param {object} options.messageAttributes
         * @param {string} [options.messageId]
         * @param {string} [options.subject]
         * @param {SNS.SNSClientConfig} [options.clientConfig={}]
         *
         * @returns {Promise<SNS.PublishCommandOutput>} SNS publish result
         */
        async publish({
            topicArn,
            message,
            messageAttributes,
            messageId,
            subject,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new SNS.PublishCommand({
                        TopicArn: topicArn,
                        Message: message,
                        MessageAttributes: messageAttributes,
                        MessageDeduplicationId: messageId,
                        Subject: subject,
                    })),
                action: 'sns publish message',
                logResponse: true,
            });
        },

        /**
         * Subsribes a topic to an endpoint
         *
         * @param {object} options
         * @param {string} options.topicArn
         * @param {'lambda'} options.protocol
         * @param {string} options.endpoint
         * @param {string} options.filter
         * @param {SNS.SNSClientConfig} [options.clientConfig={}]
         *
         * @returns {Promise<SNS.SubscribeCommandOutput>} SNS subscribe to endpoint
         */
        async subscribe({
            topicArn,
            protocol,
            endpoint,
            filter,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new SNS.SubscribeCommand({
                        TopicArn: topicArn,
                        Protocol: protocol,
                        Endpoint: endpoint,
                        Attributes: {
                            FilterPolicy: filter,
                        },
                    })),
                action: 'sns subscribe',
                logResponse: true,
            });
        },

        /**
         * Modifies an existing topic subscription
         *
         * @param {object} options
         * @param {string} options.subscriptionArn
         * @param {string} options.attributeName
         * @param {string} options.value
         * @param {SNS.SNSClientConfig} [options.clientConfig={}]
         *
         * @returns {Promise<SNS.SetSubscriptionAttributesCommandOutput>} SNS modify subscription
         */
        async modifySubscription({
            subscriptionArn,
            attributeName,
            value,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new SNS.SetSubscriptionAttributesCommand({
                        SubscriptionArn: subscriptionArn,
                        AttributeName: attributeName,
                        AttributeValue: value,
                    })),
                action: 'sns modify subscription',
                logResponse: true,
            });
        },

        /**
         * Unsubsribes a topic subscription
         *
         * @param {object} options
         * @param {string} options.subscriptionArn
         * @param {SNS.SNSClientConfig} [options.clientConfig={}]
         *
         * @returns {Promise<SNS.UnsubscribeCommandOutput>} SNS unsubscribe
         */
        async unsubscribe({
            subscriptionArn,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new SNS.UnsubscribeCommand({
                        SubscriptionArn: subscriptionArn,
                    })),
                action: 'sns unsubscribe',
                logResponse: true,
            });
        },
    };

    return Object.assign(target, moduleExports);
};

export const SNSGateway = SNSGatewayTrait();
