import { ConfigTrait } from './config.common';
import { Constants } from '../constants/constants.common';
import { Ultilities } from '../ultilities/ultilities.common';
import { S3Gateway } from '../gateways/aws/gateway.aws.s3';

/**
 * Retrieves configs for the application from S3
 *
 * @author Duc Minh Ha
 *
 * @since 2020-10-14
 *
 * @param {moduleExports} [target={}]
 *
 * @returns S3 Config class
 */
export const S3ConfigTrait = (target = {}) => {
    const traits = ConfigTrait(target);
    const parent = { ...traits };

    const moduleExports = {
        ...traits,

        /**
         * @returns Name of the bucket that contains the S3 parameter file
         */
        getS3ConfigBucket() {
            return target.getConfig()[Constants.DEFAULT_ENVIRONMENT_CONFIG_BUCKET];
        },

        /**
         * @returns Path to the S3 parameter file
         */
        getS3ConfigPath() {
            return target.getConfig()[Constants.DEFAULT_ENVIRONMENT_CONFIG_PATH];
        },

        /**
         * Retrieves config from S3 and caches it
         * This needs to be the first module that gets called
         *
         * @returns {Promise<void>}
         */
        async initialize() {
            return Ultilities.getAsyncHandler({
                callback: async () => {
                    if (!target.$config) {
                        // Assign environment variables
                        const envConfig = parent.getConfig();
                        // If we are debugging we are not interested in retrieving configs from S3
                        if (envConfig.DEBUG) return;

                        // Retrieve the rest from S3
                        const response = await S3Gateway.getObject({
                            bucketName: target.getS3ConfigBucket(),
                            key: target.getS3ConfigPath(),
                        });
                        const jsonValue = await Ultilities.streamToString(response.Body);
                        // eslint-disable-next-line no-console
                        console.log(jsonValue);
                        Object.assign(target.$config, Ultilities.fromJSON(jsonValue));
                    }
                },
                action: 's3 config initialize',
            });
        },
    };

    return Object.assign(target, moduleExports);
};

export const S3Config = S3ConfigTrait();
