import { WebConstants } from '../constants/constants.web';
import { Ultilities } from '../ultilities/ultilities.common';

// Traits
import { ConfigurableTrait } from './configurable';

/**
 * @author Duc Minh Ha
 *
 * @since 2020-10-14
 *
 * @param {moduleExports} [target={}]
 *
 * @returns Gateway base trait
 */
export const GatewayTrait = (target = {}) => {
    const traits = ConfigurableTrait(target);
    const moduleExports = {
        ...traits,

        /**
         * Creates a client for the gateway if not already
         *
         * @returns {object}
         *
         * @throws {Exception}
         */
        getClient() {
            throw WebConstants.getExceptionMessages()[WebConstants.NOT_IMPLEMENTED_EXCEPTION_CODE];
        },

        /**
         * Common handling of gateway responses
         *
         * @param {object} options
         * @param {() => Promise<*>} options.callback
         * @param {string} [options.action='gateway handler']
         * @param {boolean} [options.log]
         * @param {boolean} [options.logResponse]
         * @param {boolean} [options.logJSON]
         * @param {number} [options.maxRetries]
         * @param {(errorMessage: string, exception: Error) => Promise<void>} [options.errorHandler]
         *
         * @returns {Promise<*>}
         *
         * @throws {Error}
         */
        async getGatewayHandler({
            callback,
            action = 'gateway handler',
            log,
            logResponse,
            logJSON,
            maxRetries,
            errorHandler,
        }) {
            return Ultilities.getAsyncHandler({
                callback,
                action,
                log,
                logResponse,
                logJSON,
                maxRetries,
                errorHandler: (errorMessage, exception) => {
                    let err = exception;
                    // if we are debugging check axios error
                    if (err.isAxiosError && err.response) {
                        err = err.response.data;
                    }
                    if (errorHandler) {
                        return errorHandler(errorMessage, err);
                    }
                    throw err;
                },
            });
        },
    };

    return Object.assign(target, moduleExports);
};
