import { WebConstants } from '../constants/constants.web';

/**
 * @author Duc Minh Ha
 *
 * @since 2020-10-14
 *
 * @param {moduleExports} [target={}]
 *
 * @returns Base class of objects that can have configurations attached
 */
export const ConfigurableTrait = (target = {}) => {
    const moduleExports = {
        /**
         * Configuration object for the class
         *
         * @returns {object}
         *
         * @throws {Exception}
         */
        getConfig() {
            throw WebConstants.getExceptionMessages()[WebConstants.NOT_IMPLEMENTED_EXCEPTION_CODE];
        },
    };

    return Object.assign(target, moduleExports);
};
