import { Config } from '../../../common/config.common';

// Traits
import { GatewayTrait } from '../../../traits/gateway';

/**
 * @author Duc Minh Ha
 *
 * @since 2020-10-14
 *
 * @param {moduleExports} [target={}]
 *
 * @returns {moduleExports} Gateway base trait
 */
export const AWSGatewayTrait = (target = {}) => {
    const traits = GatewayTrait(target);

    const moduleExports = {
        ...traits,

        /**
         * Do no use directly call getClient instead
         *
         * @type {{
         *  [region: string]: object
         * }}
         */
        $clients: {},

        /**
         * @returns {object} Common gateway configs
         */
        getConfig() {
            return {
                region: Config.getConfig().AWS_REGION,
                // Try multiple times in case connection reset
                maxAttempts: 3,
            };
        },

        /**
         * Creates a client if not already
         *
         * @param {object} clientConstructor
         * @param {object} [config={}]
         *
         * @returns {object}
         */
        getClient(clientConstructor, config = {}) {
            const clientRegion = config.region || target.getConfig().region;
            if (!target.$clients[clientRegion]) {
                Object.assign(target.$clients, {
                    // eslint-disable-next-line new-cap
                    [clientRegion]: new clientConstructor({
                        ...target.getConfig(),
                        ...config,
                        region: clientRegion, // overrides the default region
                    }),
                });
            }

            return target.$clients[clientRegion];
        },
    };

    return Object.assign(target, moduleExports);
};
