import * as Cognito from '@aws-sdk/client-cognito-identity-provider';

// Traits
import { AWSGatewayTrait } from './traits/gateway.aws';

/**
 * @author Duc Minh
 *
 * @since 2022-02-02
 *
 * @param {moduleExports} [target={}]
 *
 * @returns Gateway for interacting with Cognito servers
 */
export const CognitoGatewayTrait = (target = {}) => {
    const traits = AWSGatewayTrait(target);
    const parent = { ...traits };

    const moduleExports = {
        ...traits,

        /**
         * Creates a connection to Cognito if not already
         *
         * @param {Cognito.CognitoIdentityProviderClientConfig} [config]
         *
         * @returns {Cognito.CognitoIdentityProviderClient}
         */
        getClient(config) {
            return parent.getClient(Cognito.CognitoIdentityProviderClient, config);
        },

        /**
         * Put an object into Cognito in using the specified bucket and key
         *
         * @param {object} options
         * @param {string} options.username
         * @param {string} [options.userPoolId]
         * @param {Cognito.CognitoIdentityProviderClientConfig} [options.clientConfig]
         *
         * @returns {Promise<Cognito.GetUserCommandOutput>}
         */
        async getUser({
            username,
            userPoolId,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new Cognito.AdminGetUserCommand({
                        Username: username,
                        UserPoolId: userPoolId,
                    })),
                action: 'cognito admin get user',
            });
        },
    };

    return Object.assign(target, moduleExports);
};

export const CognitoGateway = CognitoGatewayTrait();
