import * as SSM from '@aws-sdk/client-ssm';

// Traits
import { AWSGatewayTrait } from './traits/gateway.aws';

/**
 * @author Duc Minh Ha
 *
 * @since 2020-12-17
 *
 * @param {moduleExports} [target={}]
 *
 * @returns Gateway for interacting with SES servers
 */
export const SSMGatewayTrait = (target = {}) => {
    const traits = AWSGatewayTrait(target);
    const parent = { ...traits };

    const moduleExports = {
        ...traits,

        /**
         * Creates a connection to SSM if not already
         *
         * @param {SSM.SSMClientConfig} [config]
         *
         * @returns {SSM.SSMClient}
         */
        getClient(config) {
            return parent.getClient(SSM.SSMClient, config);
        },

        /**
         * Retrieves the value in parameter store
         *
         * @param {string} name
         * @param {boolean} [withDecryption=false]
         * @param {SSM.SSMClientConfig} [options.clientConfig]
         *
         * @returns {SSM.GetParameterCommandOutput}
         */
        async getParameter({
            name,
            withDecryption,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new SSM.GetParameterCommand({
                        Name: name,
                        WithDecryption: withDecryption,
                    })),
                action: 'ssm get parameter',
            });
        },

        /**
         * Retrieves the value in parameter store
         *
         * @param {string} name
         * @param {boolean} [withDecryption=false]
         * @param {boolean} [recursive=false]
         * @param {SSM.SSMClientConfig} [options.clientConfig]
         *
         * @returns {SSM.GetParametersByPathCommandOutput}
         */
        async getParameterByPath({
            name,
            withDecryption,
            recursive,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new SSM.GetParametersByPathCommand({
                        Name: name,
                        WithDecryption: withDecryption,
                        Recursive: recursive,
                    })),
                action: 'ssm get parameter by path',
            });
        },
    };

    return Object.assign(target, moduleExports);
};

export const SSMGateway = SSMGatewayTrait();
