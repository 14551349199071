import JiraApi from 'jira-client';

// Traits
import { GatewayTrait } from '../traits/gateway';

/**
 * @author Duc Minh
 *
 * @since 2022-05-16
 *
 * @param {moduleExports} [target={}]
 *
 * @returns Gateway for interacting with Jira servers
 */
export const JiraGatewayTrait = (target = {}) => {
    const traits = GatewayTrait(target);

    const moduleExports = {
        ...traits,

        /**
         * Jira clients
         * Do no use directly call getClient instead
         *
         * @type {{
         *  [host: string]: JiraApi
         * }}
         */
        $clients: {},

        /**
         * @returns {JiraApi.JiraApiOptions}
         */
        getConfig() {
            return {
                protocol: 'https',
                apiVersion: '3',
                strictSSL: true,
            };
        },

        /**
         * Creates a connection to Jira if not already
         *
         * @param {string} host
         * @param {JiraApi.JiraApiOptions} [config={}]
         *
         * @returns {JiraApi}
         */
        getClient(host, config = {}) {
            if (!target.$clients[host]) {
                Object.assign(target.$clients, {
                    [host]: new JiraApi({
                        ...target.getConfig(),
                        host,
                        ...config,
                    }),
                });
            }
            return target.$clients[host];
        },

        /**
         * @param {object} options
         * @param {string} options.host
         * @param {JiraApi.JiraApiOptions} [options.clientConfig]
         * @param {string} options.issueId
         * @param {string} options.property
         *
         * @returns {Promise<*>}
         */
        async getIssueProperty({
            host,
            clientConfig,
            issueId,
            property,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(host, clientConfig).getIssueProperty(issueId, property),
                action: 'jira get issue property',
            });
        },

        /**
         * @param {object} options
         * @param {string} options.host
         * @param {JiraApi.JiraApiOptions} [options.clientConfig]
         * @param {string} options.issueId
         *
         * @returns {Promise<*>}
         */
        async getIssue({
            host,
            clientConfig,
            issueId,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(host, clientConfig).getIssue(issueId),
                action: 'jira get issue',
            });
        },

        /**
         * @param {object} options
         * @param {string} options.host
         * @param {JiraApi.JiraApiOptions} [options.clientConfig]
         * @param {string} options.jql
         *
         * @returns {Promise<*>}
         */
        async search({
            host,
            clientConfig,
            jql,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(host, clientConfig).searchJira(jql),
                action: 'jira search',
            });
        },

        /**
         * @param {object} options
         * @param {string} options.host
         * @param {JiraApi.JiraApiOptions} [options.clientConfig]
         * @param {string} options.issueId
         * @param {object} options.comment https://developer.atlassian.com/cloud/jira/platform/apis/document/structure/
         *
         * @returns {Promise<*>}
         */
        async postComment({
            host,
            clientConfig,
            issueId,
            comment,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(host, clientConfig).addCommentAdvanced(issueId, comment),
                action: 'jira post comment',
            });
        },
    };

    return Object.assign(target, moduleExports);
};

export const JiraGateway = JiraGatewayTrait();
