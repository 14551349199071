/**
 * Defines common constants that do not change
 *
 * @author Duc Minh Ha
 *
 * @since 2020-10-19
 */
export const Constants = {
    // Genaral
    SYSTEM_USER: 'System',
    DEFAULT_S3_BUCKET_REGION: 'ap-southeast-2',
    DEFAULT_ENVIRONMENT_CONFIG_BUCKET: 'config_bucket_name',
    DEFAULT_ENVIRONMENT_CONFIG_PATH: 'config_path',
    DEFAULT_PARTITION_KEY_NAME: 'id',
    DEFAULT_UUID_VERSION: 'v4',
    DEFAULT_CRYPTO_ALGORITHM: 'sha256',
    DEFAULT_CRYPTO_DIGEST: 'base64',
    UNKNOWN: 'Unknown',
    AWS_IAM_POLICY_VERSION: '2012-10-17',

    // Date
    TODAY_DATE_FORMAT: 'Today h:mm A',
    DEFAULT_DATE_FORMAT: 'D MMM YYYY h:mm A',
};
