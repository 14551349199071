import * as Slack from '@slack/web-api';

// Traits
import { GatewayTrait } from '../traits/gateway';

/**
 * @author Duc Minh
 *
 * @since 2022-05-16
 *
 * @param {moduleExports} [target={}]
 *
 * @returns Gateway for interacting with Slack servers
 */
export const SlackGatewayTrait = (target = {}) => {
    const traits = GatewayTrait(target);

    const moduleExports = {
        ...traits,

        /**
         * Slack clients
         * Do no use directly call getClient instead
         *
         * @type {{
         *  [apiKey: string]: Slack.WebClient
         * }}
         */
        $clients: {},

        /**
         * @returns {Slack.WebClientOptions}
         */
        getConfig() {
            return {
            };
        },

        /**
         * Creates a connection to Slack if not already
         *
         * @param {string} apiKey
         * @param {Slack.WebClientOptions} [config={}]
         *
         * @returns {Slack.WebClient}
         */
        getClient(apiKey, config = {}) {
            if (!target.$clients[apiKey]) {
                Object.assign(target.$clients, {
                    [apiKey]: new Slack.WebClient(apiKey, {
                        ...target.getConfig(),
                        ...config,
                    }),
                });
            }
            return target.$clients[apiKey];
        },

        /**
         * @param {object} options
         * @param {string} options.apiKey
         * @param {Slack.WebClientOptions} [options.clientConfig]
         * @param {Slack.ChatPostMessageArguments} options.message
         *
         * @returns {Promise<Slack.ChatPostMessageResponse>}
         */
        async postMessage({
            apiKey,
            clientConfig,
            message,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(apiKey, clientConfig).chat.postMessage(message),
                action: 'slack post message',
            });
        },
    };

    return Object.assign(target, moduleExports);
};

export const SlackGateway = SlackGatewayTrait();
