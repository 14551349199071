import MySQL from 'mysql2/promise';

// Traits
import { GatewayTrait } from '../traits/gateway';

/**
 * @author Duc Minh
 *
 * @since 2021-02-23
 *
 * @param {moduleExports} [target={}]
 *
 * @returns Gateway for interacting with MySQL servers
 */
export const MySQLGatewayTrait = (target = {}) => {
    const traits = GatewayTrait(target);

    const moduleExports = {
        ...traits,

        /**
         * MySQL clients
         * Do no use directly call getClient instead
         *
         * @type {{
         *  [connectionId: string]: MySQL.Pool
         * }}
         */
        $clients: {},

        /**
         * @returns {MySQL.PoolOptions}
         */
        getConfig() {
            return {
                multipleStatements: true,
                waitForConnections: true,
                connectionLimit: 1000,
                queueLimit: 0,
            };
        },

        /**
         * Creates a connection pool to MySQL if not already
         *
         * @param {string} connectionId
         * @param {MySQL.PoolOptions} [config={}]
         *
         * @returns {MySQL.Pool}
         */
        getClient(connectionId, config = {}) {
            if (!target.$clients[connectionId]) {
                Object.assign(target.$clients, {
                    [connectionId]: MySQL.createPool({
                        ...target.getConfig(),
                        ...config,
                    }),
                });
            }
            return target.$clients[connectionId];
        },

        /**
         * @param {object} options
         * @param {string} options.connectionId
         * @param {MySQL.ConnectionOptions} [options.clientConfig]
         * @param {string | string[]} options.query
         * @param {any | any[] | { [param: string]: any }} [options.values]
         *
         * @returns {Promise<[T, MySQL.FieldPacket[]]>}
         */
        async getSQLHandler({
            connectionId,
            clientConfig,
            query,
            values,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(connectionId, clientConfig).query({
                    sql: query instanceof Array
                        ? query.join('\n')
                        : query,
                    values,
                    nestTables: true,
                }),
                action: 'mysql execute query',
                errorHandler: (errorMessage, exception) => {
                    // Log the failing query
                    console.error(query);
                    throw exception;
                },
            });
        },
    };

    return Object.assign(target, moduleExports);
};

export const MySQLGateway = MySQLGatewayTrait();
