import { ConfigTrait } from './config.common';
import { Constants } from '../constants/constants.common';
import { Ultilities } from '../ultilities/ultilities.common';
import { SSMGateway } from '../gateways/aws/gateway.aws.ssm';

/**
 * Retrieves configs for the application from SSM
 *
 * @author Duc Minh Ha
 *
 * @since 2020-10-14
 *
 * @param {moduleExports} [target={}]
 *
 * @returns SSM Config class
 */
export const SSMConfigTrait = (target = {}) => {
    const traits = ConfigTrait(target);
    const parent = { ...traits };

    const moduleExports = {
        ...traits,

        /**
         * @returns Path to the SSM parameter
         */
        getSSMConfigPath() {
            return parent.getConfig()[Constants.DEFAULT_ENVIRONMENT_CONFIG_PATH];
        },

        /**
         * Retrieves config from SSM and caches it
         * This needs to be the first module that gets called
         *
         * @returns {Promise<void>}
         */
        async initialize() {
            // Not debugging so use SSM
            if (!target.$config) {
                if (process.env.DEBUG) {
                    target.getConfig();
                } else {
                    const response = await SSMGateway.getParameter({
                        name: target.getSSMConfigPath(),
                    });
                    Object.assign(target.$config, Ultilities.fromJSON(response).Parameter.Value);
                }
            }
        },
    };

    return Object.assign(target, moduleExports);
};

export const SSMConfig = SSMConfigTrait();
