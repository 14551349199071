import * as STS from '@aws-sdk/client-sts';

// Traits
import { AWSGatewayTrait } from './traits/gateway.aws';

/**
 * @author Duc Minh
 *
 * @since 2021-02-23
 *
 * @param {moduleExports} [target={}]
 *
 * @returns Gateway for interacting with STS servers
 */
export const STSGatewayTrait = (target = {}) => {
    const traits = AWSGatewayTrait(target);
    const parent = { ...traits };

    const moduleExports = {
        ...traits,

        /**
         * Creates a connection to STS if not already
         *
         * @param {STS.STSClientConfig} [config]
         *
         * @returns {STS.STSClient}
         */
        getClient(config) {
            return parent.getClient(STS.STSClient, config);
        },

        /**
         * Assume role using role arn
         *
         * @param {object} options
         * @param {string} options.roleArn
         * @param {string} options.sessionName
         * @param {STS.STSClientConfig} [options.clientConfig]
         *
         * @returns {Promise<STS.AssumeRoleCommandOutput>}
         */
        async assumeRole({
            roleArn,
            sessionName,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new STS.AssumeRoleCommand({
                        RoleArn: roleArn,
                        RoleSessionName: sessionName,
                    })),
                action: 'STS get object',
            });
        },
    };

    return Object.assign(target, moduleExports);
};

export const STSGateway = STSGatewayTrait();
