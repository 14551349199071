import * as DynamoDB from '@aws-sdk/client-dynamodb';
import * as DynamoDBUtils from '@aws-sdk/util-dynamodb';

import { AWSConstants } from '../../constants/constants.aws';
import { Exception } from '../../exceptions/exception.common';
import { Ultilities } from '../../ultilities/ultilities.common';
import { WebConstants } from '../../constants/constants.web';

// Traits
import { AWSGatewayTrait } from './traits/gateway.aws';

/**
 * @author Duc Minh Ha
 *
 * @since 2020-10-14
 *
 * @param {moduleExports} [target={}]
 *
 * @returns Gateway for interacting with AWS DynamoDB
 */
export const DynamoDBGatewayTrait = (target = {}) => {
    const traits = AWSGatewayTrait(target);
    const parent = { ...traits };

    const moduleExports = {
        ...traits,

        /**
         * Creates a connection to DynamoDB if not already with the region provided
         *
         * @param {DynamoDB.DynamoDBClientConfig} [config]
         *
         * @returns {DynamoDB.DynamoDBClient}
         */
        getClient(config) {
            return parent.getClient(DynamoDB.DynamoDBClient, config);
        },

        /**
         * Queries the target table for records
         *
         * @param {object} options
         * @param {string} options.tableName
         * @param {string} [options.continuationToken]
         * @param {DynamoDB.DynamoDBClientConfig} [options.clientConfig]
         *
         * @returns {Promise<DynamoDB.ScanOutput>} DynamoDB Scan result
         */
        async scan({
            tableName,
            continuationToken,
            clientConfig,
        }) {
            // build request following the provided format from
            // https://docs.aws.amazon.com/amazondynamodb/latest/APIReference/API_BatchGetItem.html
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new DynamoDB.ScanCommand({
                        TableName: tableName,
                        ExclusiveStartKey: continuationToken,
                    })),
                action: 'dynamodb scan',
            });
        },

        /**
         * Queries the target table for records
         *
         * @param {object} options
         * @param {string} options.tableName
         * @param {Array<object>} options.records
         * @param {DynamoDB.DynamoDBClientConfig} [options.clientConfig]
         *
         * @returns {Promise<*>}
         */
        async batchWrite({
            tableName,
            records,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => Ultilities.timeContinuableProcess(
                    async (continuationToken) => {
                        const requestItems = continuationToken.splice(0, AWSConstants.DYNAMODB_MAX_BATCH_WRITE);
                        if (requestItems.length === 0) return { size: 0 };
                        const response = await target.getClient(clientConfig)
                            .send(new DynamoDB.BatchWriteItemCommand({
                                RequestItems: {
                                    [tableName]: requestItems,
                                },
                            }));
                        const unprocessedItems = response.UnprocessedItems[tableName] || [];
                        return {
                            continuationToken: [
                                ...unprocessedItems,
                                ...continuationToken,
                            ],
                            size: requestItems.length,
                        };
                    },
                    ({ output }) => output.size > 0,
                    'dynamo batch write continuable process',
                )(target.transformBatchRequests(records)),
                action: 'dynamo batch write',
            });
        },

        /**
         * @param {Array<object>} records
         *
         * @returns Mapped DynamoDB API batch request
         */
        transformBatchRequests(records) {
            const requests = records.map((record) => ({
                PutRequest: {
                    Item: DynamoDBUtils.marshall(record),
                },
            }));
            return requests;
        },

        /**
         * Create a single batch put command and send as a batch write request
         *
         * @param {object} options
         * @param {string} options.tableName
         * @param {object} options.record
         * @param {DynamoDB.DynamoDBClientConfig} [options.clientConfig]
         *
         * @returns {BatchWriteItemOutput}
         */
        async put({
            tableName,
            record,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.batchWrite({
                    tableName,
                    records: [
                        record,
                    ],
                    clientConfig,
                }),
                action: `dynamo put record in table ${tableName}`,
                logResponse: true,
                errorHandler: (errorMessage, error) => {
                    if (error.message.includes(WebConstants.NOT_FOUND_EXCEPTION)) {
                        throw new Exception(WebConstants.NOT_FOUND_EXCEPTION, error);
                    } else {
                        throw new Exception(errorMessage, error);
                    }
                },
            });
        },
    };

    return Object.assign(target, moduleExports);
};

export const DynamoDBGateway = DynamoDBGatewayTrait();
