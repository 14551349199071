import * as AutoScaling from '@aws-sdk/client-auto-scaling';

// Traits
import { AWSGatewayTrait } from './traits/gateway.aws';

/**
 * @author Duc Minh
 *
 * @since 2021-02-23
 *
 * @param {moduleExports} [target={}]
 *
 * @returns Gateway for interacting with AutoScaling servers
 */
export const AutoScalingGatewayTrait = (target = {}) => {
    const traits = AWSGatewayTrait(target);
    const parent = { ...traits };

    const moduleExports = {
        ...traits,

        /**
         * Creates a connection to AutoScaling if not already
         *
         * @param {AutoScaling.AutoScalingClientConfig} [config]
         *
         * @returns {AutoScaling.AutoScalingClient}
         */
        getClient(config) {
            return parent.getClient(AutoScaling.AutoScalingClient, config);
        },

        /**
         * Get ASG information by name
         *
         * @param {object} options
         * @param {string} options.name
         * @param {AutoScaling.AutoScalingClientConfig} [options.clientConfig]
         *
         * @returns {Promise<AutoScaling.DescribeAutoScalingInstancesCommandOutput>}
         */
        async getByName({
            name,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new AutoScaling.DescribeAutoScalingGroupsCommand({
                        AutoScalingGroupNames: [
                            name,
                        ],
                    })),
                action: 'AutoScaling get by name',
            });
        },
    };

    return Object.assign(target, moduleExports);
};

export const AutoScalingGateway = AutoScalingGatewayTrait();
