import * as Lambda from '@aws-sdk/client-lambda';

// Traits
import { AWSGatewayTrait } from './traits/gateway.aws';

/**
 * @author Duc Minh Ha
 *
 * @since 2020-10-14
 *
 * @param {moduleExports} [target={}]
 *
 * @returns Gateway for interacting with AWS Lambda
 */
export const LambdaGatewayTrait = (target = {}) => {
    const traits = AWSGatewayTrait(target);
    const parent = { ...traits };

    const moduleExports = {
        ...traits,

        /**
         * Creates a connection to Lambda if not already
         *
         * @param {Lambda.LambdaClientConfig} [config]
         *
         * @returns {Lambda.LambdaClient}
         */
        getClient(config) {
            return parent.getClient(Lambda.Lambda, config);
        },

        /**
         * Invokes lambda
         *
         * @param {object} options
         * @param {string} options.server
         * @param {string} [options.payload='']
         * @param {'Event'|'RequestResponse'|'DryRun'} [options.type='RequestResponse']
         * @param {boolean} [options.log=true]
         * @param {boolean} [options.logResponse=false]
         * @param {boolean} [options.logJSON=true]
         * @param {Lambda.LambdaClientConfig} [options.clientConfig={}]
         *
         * @returns {Promise<Lambda.InvokeCommandOutput>}
         */
        async invoke({
            server,
            payload = '',
            type = 'RequestResponse',
            log = true,
            logResponse = false,
            logJSON = true,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new Lambda.InvokeCommand({
                        FunctionName: server,
                        InvocationType: type,
                        Payload: payload,
                    })),
                action: 'lambda invoke',
                log,
                logResponse,
                logJSON,
            });
        },

        /**
         * Invokes lambda
         *
         * @param {object} options
         * @param {string} options.eventSourceArn
         * @param {string} options.lambdaTargetArn
         * @param {number} [options.batchSize]
         * @param {Lambda.LambdaClientConfig} [options.clientConfig={}]
         *
         * @returns {Promise<Lambda.CreateEventSourceMappingCommandOutput>}
         */
        async subscribe({
            eventSourceArn,
            lambdaTargetArn,
            batchSize,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new Lambda.CreateEventSourceMappingCommand({
                        EventSourceArn: eventSourceArn,
                        FunctionName: lambdaTargetArn,
                        BatchSize: batchSize,
                    })),
                action: 'lambda subscribe',
                logResponse: true,
            });
        },

        /**
         * Invokes lambda
         *
         * @param {object} options
         * @param {string} options.subscriptionId
         * @param {Lambda.LambdaClientConfig} [options.clientConfig={}]
         *
         * @returns {Promise<Lambda.DeleteEventSourceMappingCommandOutput>}
         */
        async unsubscribe({
            subscriptionId,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new Lambda.DeleteEventSourceMappingCommand({
                        UUID: subscriptionId,
                    })),
                action: 'lambda unsubscribe',
                logResponse: true,
            });
        },
    };

    return Object.assign(target, moduleExports);
};

export const LambdaGateway = LambdaGatewayTrait();
