import date from 'date-and-time';
import Lodash from 'lodash';
import Mustache from 'mustache';
import * as uuid from 'uuid';
import stringify from 'json-stringify-safe';

import { Constants } from '../constants/constants.common';

/**
 * Common ultilities
 *
 * @author Duc Minh Ha
 *
 * @since 2020-10-19
 */
export const Ultilities = {
    /**
     * @param {number} duration
     *
     * @returns a timed promise for the given duration
     */
    delay(duration) {
        return new Promise((resolve) => {
            setTimeout(resolve, duration);
        });
    },

    /**
     * @param {ReadableStream} stream
     *
     * @returns {Promise<string>}
     */
    async streamToString(stream) {
        let result = '';
        return new Promise((resolve, reject) => {
            stream.on('data', (data) => {
                result += data.toString();
            });
            stream.on('end', () => {
                resolve(result);
            });
            stream.on('error', (err) => {
                reject(err);
            });
        });
    },

    /**
     * Get the timestamp of now in ISO format
     *
     * @returns {string}
     *
     * @example nowISOString() => '2020-11-30T18:30:11.178Z'
     */
    now() {
        return new Date().toISOString();
    },

    /**
     * Returns date component of a date/time string
     *
     * @param {string} value
     * @param {string} [format]
     * @param {boolean} [utc]
     *
     * @returns {string}
     */
    getDateString(value, format = Constants.DEFAULT_DATE_FORMAT, utc) {
        if (!value) return value;
        const dateValue = new Date(value);
        return date.format(dateValue, date.isSameDay(dateValue, new Date()) ? Constants.TODAY_DATE_FORMAT : format, utc);
    },

    /**
     * @param {number} timespan
     *
     * @returns {string}
     */
    getTimespanString(timespan) {
        if (!timespan) return null;

        let value = timespan;
        let scale = 'Sec';

        if (value > 365 * 24 * 60 * 60) {
            value /= 365 * 24 * 60 * 60;
            scale = 'Year';
        } else if (value > 30 * 24 * 60 * 60) {
            value /= 30 * 24 * 60 * 60;
            scale = 'Month';
        } else if (value > 7 * 24 * 60 * 60) {
            value /= 7 * 24 * 60 * 60;
            scale = 'Week';
        } else if (value > 24 * 60 * 60) {
            value /= 24 * 60 * 60;
            scale = 'Day';
        } else if (value > 60 * 60) {
            value /= 60 * 60;
            scale = 'Hour';
        } else if (value > 60) {
            scale = 'Min';
            value /= 60;
        }

        return `${value.toFixed(1)} ${scale}(s)`;
    },

    /**
     * @param {string} value
     *
     * @returns {boolean}
     */
    isEmptyOrWhiteSpace(value) {
        return !/\S/.test(value) || Lodash.isEmpty(value);
    },

    /**
     * @param {string} value
     *
     * @returns {boolean}
     *
     * @example isNullOrEmpty(null) => true
     * @example isNullOrEmpty('') => true
     */
    isNullOrEmpty(value) {
        return (value === null || Ultilities.isEmptyOrWhiteSpace(value));
    },

    /**
     * @param {*} value
     *
     * @returns {boolean} True if the value is null, undefined or empty
     *
     * @example isNilOrEmpty(null) => true
     * @example isNilOrEmpty(undefined) => true
     * @example isNilOrEmpty('') => true
     * @example isNilOrEmpty({}) => true
     * @example isNilOrEmpty([]) => true
     */
    isNilOrEmpty(value) {
        return Lodash.isNil(value) || Ultilities.isNullOrEmpty(value);
    },

    /**
     * Convert array or object to it's full enumerated values
     * This helps show hidden values that were not enumerated
     *
     * @param {*} value
     *
     * @returns {Array|object|null}
     */
    fullObject(value) {
        if (Lodash.isNil(value)) {
            return value;
        }

        const enumerator = value instanceof Array ? value : [value];
        const enumerated = enumerator.flatMap((o) => (typeof o === 'object' ? Object.getOwnPropertyNames(o)
            .reduce((prev, curr) => ({
                ...prev,
                [curr]: o[curr],
            }), {})
            : o));
        return value instanceof Array ? enumerated : enumerated[0];
    },

    /**
     * @param {*} value
     * @param {Array<string>} [omit=[]]
     *
     * @returns The original value with all of it's properties set to null if type is object
     * otherwise null it self
     */
    nullify(value, omit = []) {
        if (typeof value !== 'object') return null;
        return Object.keys(value)
            .filter((key) => !omit.includes(key))
            .reduce((prev, curr) => ({
                ...prev,
                [curr]: null,
            }), {});
    },

    /**
     * @param {*} value
     * @param {boolean} condition
     *
     * @returns {*} Null if the condition is true, else the value
     */
    nullIf(value, condition) {
        return condition ? null : value;
    },

    /**
     * @param {*} value
     *
     * @returns {*} Null if the value is undefined, else the value
     */
    nullIfUndefined(value) {
        return Ultilities.nullIf(value, value === undefined);
    },

    /**
     * @param {Function} callback
     *
     * @returns The amount of time indicating how long it took to execute the callback
     */
    async measureTime(callback) {
        const start = Date.now();
        const output = callback.constructor.name === 'AsyncFunction' ? await callback() : callback();

        return {
            duration: Date.now() - start,
            output,
        };
    },

    /**
     * @param {(continuationToken: *, props: *) => Promise<{
     *  continuationToken: *,
     *  size: number,
     * }>} callback
     * @param {(result: *) => *} continuer
     * @param {string} action
     *
     * @returns {(continuationToken: *, props: *) => Promise<void>} Continuable handler function
     */
    timeContinuableProcess(callback, continuer, action) {
        const handler = async (continuationToken, props) => {
            const result = await Ultilities.measureTime(async () => {
                const output = await callback(continuationToken, props);
                return output;
            });

            const minutesPerRecord = result.duration / (60 * 1000);
            console.log(
                `Took ${minutesPerRecord} minutes to process ${result.output.size} records.
                Rate is ${(60 * result.output.size) / minutesPerRecord} records per hour`,
            );

            // Continue the list
            if (continuer(result)) {
                await handler(result.output.continuationToken, props);
            } else {
                console.log(action || 'Process ended successfully...');
            }
        };
        return handler;
    },

    /**
     * Returns the stringified value with all properties
     *
     * @param {*} value
     * @param {number} [space]
     *
     * @returns {string|null}
     */
    asJSON(value, space) {
        const enumeratedValue = Ultilities.fullObject(value);
        return !Lodash.isNil(value) && typeof enumeratedValue === 'object' ? stringify(enumeratedValue, null, space) : value;
    },

    /**
     * Checks if we can parse the value as JSON
     * Returns the parsed value if valid, otherwise false
     *
     * @param {*} value
     *
     * @returns The parsed value if valid, otherwise false
     */
    fromJSON(value) {
        try {
            return JSON.parse(value);
        } catch (err) {
            return false;
        }
    },

    /**
     * Generates a UUID
     *
     * @param {'v1'|'v2'|'v3'|'v4'|'v5'} [version=Constants.DEFAULT_UUID_VERSION]
     * \
     * @returns {string}
     */
    uuid(version = Constants.DEFAULT_UUID_VERSION) {
        return uuid[version]();
    },

    /**
     * @param {*} value
     * @param {object} source
     *
     * @returns Applied source data to value using mustache notation
     */
    mustache(value, source) {
        if (Ultilities.isNilOrEmpty(source)) return value;
        const valueString = Ultilities.asJSON(value);
        const appliedValue = Mustache.render(valueString, source);
        return typeof value === 'object' ? Ultilities.fromJSON(appliedValue) : appliedValue;
    },

    /**
     * @param {number} value
     *
     * @returns Seconds as milliseconds
     */
    secondsToMilliseconds(value) {
        return value * 1000;
    },

    /**
     * Common handling of retryable requests
     *
     * @param {object} options
     * @param {() => Promise<*>} options.callback
     * @param {string|object} [options.action='retry handler']
     * @param {number} [options.maxRetries=0]
     * @param {(errorMessage: string, exception: Error) => *} [options.errorHandler]
     *
     * @returns {Promise<object>}
     *
     * @throws {Error}
     */
    async getRetryHandler({
        callback,
        action = 'retry handler',
        maxRetries = 0,
        errorHandler,
    }) {
        let exception;
        let currentTry = 0;
        while (currentTry - 1 < maxRetries) {
            try {
                const result = await callback();
                return result;
            } catch (err) {
                exception = err;
                currentTry++;
            }
        }

        if (errorHandler) {
            return errorHandler(`Failed to process ${action}`, exception);
        }
        throw exception;
    },

    /**
     * Common handling of async requests
     *
     * @param {object} options
     * @param {() => Promise<*>} options.callback
     * @param {string|object} [options.action='handler']
     * @param {boolean} [options.log=true]
     * @param {boolean} [options.logResponse=false]
     * @param {boolean} [options.logJSON=false]
     * @param {number} [options.maxRetries=0]
     * @param {(errorMessage: string, exception: Error) => *} [options.errorHandler]
     *
     * @returns {Promise<object>}
     *
     * @throws {Error}
     */
    async getAsyncHandler({
        callback,
        action = 'handler',
        log = true,
        logResponse = false,
        logJSON = true,
        maxRetries,
        errorHandler,
    }) {
        return Ultilities.getRetryHandler({
            callback: async () => {
                if (log) {
                    const logObject = {
                        context: action,
                    };
                    console.log(logJSON ? Ultilities.asJSON(logObject, 4) : logObject);
                }
                const response = await callback();
                if (log) {
                    const logObject = {
                        context: action,
                        ...(logResponse
                            ? {
                                response,
                            } : {}),
                    };
                    // eslint-disable-next-line no-console
                    console.log(logJSON ? Ultilities.asJSON(logObject, 4) : logObject);
                }
                return response;
            },
            action: `async request ${action}`,
            maxRetries,
            errorHandler,
        });
    },

    /**
     * Execute request when condition is meet
     * Will error out after a set period of time
     *
     * @param {object} options
     * @param {() => Promise<*>} options.condition
     * @param {() => Promise<*>} options.callback
     * @param {string} [options.when='true']
     * @param {number} [options.duration=10000]
     * @param {number} [options.pollInterval=1000]
     * @param {boolean} [options.log]
     * @param {boolean} [options.logResponse]
     * @param {boolean} [options.logJSON]
     * @param {(errorMessage: string, exception: Error) => *} [options.errorHandler]
     *
     * @returns {Promise<object>}
     *
     * @throws {Error}
     */
    async executeWhen({
        condition,
        callback,
        when = 'true',
        duration = 10000,
        pollInterval = 1000, // every 1 second
        log,
        logResponse,
        logJSON,
        errorHandler,
    }) {
        return new Promise((resolve, reject) => {
            let response;
            let timeout;
            let interval;
            const promiseErrorHandler = () => {
                clearTimeout(timeout);
                clearInterval(interval);
                (errorHandler || ((errorMessage, exception) => {
                    reject(exception);
                }))();
            };
            // Our timeout will clean up when the duration has finished
            timeout = setTimeout(() => {
                const errorMessage = `Execution has timedout after ${duration} millisecons`;
                const error = new Error(errorMessage);
                promiseErrorHandler(errorMessage, error);
            }, duration);
            // Our interval will poll until our condition is meet, then execute the callback
            interval = setInterval(async () => Ultilities.getAsyncHandler({
                callback: async () => {
                    const execute = await condition();
                    if (execute) {
                        response = await callback();
                        clearTimeout(timeout);
                        clearInterval(interval);
                        resolve(response);
                    }
                },
                action: `execute when ${when}`,
                log,
                logResponse,
                logJSON,
                errorHandler: promiseErrorHandler,
            }), pollInterval);
        });
    },

    /**
     * @param {Array<*>} sequence
     *
     * @param {(data: *) => Promise<*>} callback
     */
    async resolvePromiseSequence(sequence, callback) {
        return sequence.reduce(async (prev, curr) => [
            ...(await prev),
            await callback(curr),
        ], Promise.resolve([]));
    },

    /**
     * @param {() => Generator} callback
     *
     * @returns {Array<object>}
     */
    generateArray(callback) {
        const generator = callback();
        return Array.from(generator);
    },
};
