import * as S3 from '@aws-sdk/client-s3';

// Traits
import { AWSGatewayTrait } from './traits/gateway.aws';

/**
 * @author Duc Minh
 *
 * @since 2021-02-23
 *
 * @param {moduleExports} [target={}]
 *
 * @returns Gateway for interacting with S3 servers
 */
export const S3GatewayTrait = (target = {}) => {
    const traits = AWSGatewayTrait(target);
    const parent = { ...traits };

    const moduleExports = {
        ...traits,

        /**
         * Creates a connection to S3 if not already
         *
         * @param {S3.S3ClientConfig} [config]
         *
         * @returns {S3.S3Client}
         */
        getClient(config) {
            return parent.getClient(S3.S3Client, config);
        },

        /**
         * Get an object into S3 in using the specified bucket and key
         *
         * @param {object} options
         * @param {string} options.bucketName
         * @param {string} options.key
         * @param {S3.S3ClientConfig} [options.clientConfig]
         *
         * @returns {Promise<S3.GetObjectCommandOutput>}
         */
        async getObject({
            bucketName,
            key,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new S3.GetObjectCommand({
                        Bucket: bucketName,
                        Key: key,
                    })),
                action: 's3 get object',
            });
        },

        /**
         * Put an object into S3 in using the specified bucket and key
         *
         * @param {object} options
         * @param {string} options.bucketName
         * @param {string} options.key
         * @param {string} [options.content]
         * @param {string} [options.contentType]
         * @param {S3.S3ClientConfig} [options.clientConfig]
         *
         * @returns {Promise<S3.PutObjectCommandOutput>}
         */
        async putObject({
            bucketName,
            key,
            content,
            contentType,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new S3.PutObjectCommand({
                        Body: content,
                        Bucket: bucketName,
                        Key: key,
                        ContentType: contentType,
                    })),
                action: 's3 put object',
                logResponse: true,
            });
        },

        /**
         * Retrieve bucket objects, continuing from the token provided
         *
         * @param {object} options
         * @param {string} options.bucketName
         * @param {string} options.prefix
         * @param {string} [options.continuationToken]
         * @param {S3.S3ClientConfig} [options.clientConfig]
         *
         * @returns {S3.ListObjectsV2CommandOutput} S3 object list
         */
        async listObjects({
            bucketName,
            prefix,
            continuationToken,
            clientConfig,
        }) {
            return target.getGatewayHandler({
                callback: async () => target.getClient(clientConfig)
                    .send(new S3.ListObjectsV2Command({
                        Bucket: bucketName,
                        Prefix: prefix,
                        ...(continuationToken ? {
                            ContinuationToken: continuationToken,
                        } : {}),
                    })),
                action: `list objects for ${bucketName}/${prefix} with token ${continuationToken}`,
            });
        },
    };

    return Object.assign(target, moduleExports);
};

export const S3Gateway = S3GatewayTrait();
