/**
 * Defines common constants that do not change
 *
 * @author Duc Minh Ha
 *
 * @since 2020-10-19
 */
export const AWSConstants = {
    DYNAMODB_MAX_BATCH_WRITE: 25,
};
