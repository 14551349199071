import Lodash from 'lodash';

import { Ultilities } from '../ultilities/ultilities.common';

/**
 * Exception handling
 *
 * @author Duc Minh Ha
 *
 * @since 2020-10-19
 *
 * @extends {Error}
 */
export class Exception extends Error {
    static errorSeparator = '\n';

    innerError = null;

    /**
     * @param {string} message
     * @param {Error} [error]
     * @param {bool} [skipTrace=false]
     * @param {bool} [logAsIs=false]
     */
    constructor(message, error, skipTrace = false, logAsIs = true) {
        super(message);
        if (error) {
            this.innerError = Lodash.omit(Ultilities.fullObject(error), skipTrace ? ['stack'] : []);
            // eslint-disable-next-line no-console
            console.error(logAsIs ? Ultilities.asJSON(this.innerError) : this.innerError.message);
        }
    }
}
