import { WebConstants } from '../constants/constants.web';
import { Exception } from './exception.common';

/**
 * Exception handling
 *
 * @author Duc Minh Ha
 *
 * @since 2020-10-19
 *
 * @extends {Error}
 */
export class ControllerException extends Exception {
    context = 'Controller Exception';

    statusCode = WebConstants.INTERNAL_SERVER_ERROR_EXCEPTION_CODE;

    error = WebConstants.getExceptionMessages()[this.statusCode];

    message = this.error;

    trace = null;

    /**
     * @param {string} context
     * @param {string|number} code
     * @param {string} message
     * @param {string} [trace]
     */
    constructor(context, statusCode, message, trace) {
        super(message);
        this.context = context;
        this.statusCode = statusCode;
        this.error = WebConstants.getExceptionMessages()[statusCode];
        this.message = message;
        this.trace = trace;
    }
}
